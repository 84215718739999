import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  CircularProgress,
  Button,
  DialogTitle,
  DialogActions,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  SelectChangeEvent,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/store";
import {
  disabledKey,
  enableKey,
  getBillingDetails,
  resetPrimaryKey,
  resetSecondaryKey,
} from "../../store/thunk/billingThunk";
import dotnetApiClient from "../../config/dotnetApiClient";
import { logErrorFunction, logFunction } from "../../helper/service";

export function ViewBillingEntityDialog({ open, handleClose, EntityId }: any) {
  const dispatch = useAppDispatch();
  const [btnLoading, setBtnLoading] = useState(false);
  const [showPrimaryKey, setShowPrimaryKey] = useState(false);
  const [showSecondaryKey, setShowSecondaryKey] = useState(false);
  const { entityDetails, setEntityDetails } = useAppSelector(
    (state: any) => state.billingReducer
  );
  const [userPersona, setUserPersona] = useState<string>("Lawyer");
  const [users, setUsers] = useState<any[]>([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const accessToken = localStorage.getItem("accessToken") || null;

  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    userPersona: "Lawyer",
  });
  const [creatingUser, setCreatingUser] = useState(false);
  const [showNewUserForm, setShowNewUserForm] = useState(false);

  useEffect(() => {
    const fetchEntityDetails = async () => {
      try {
        const response = await dotnetApiClient().get(
          `/UiUser/billingentity/${EntityId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        logFunction("Full response:" + response);
        if (response.data) {
          setEntityDetails(response.data);
        } else {
          logErrorFunction("No data returned from API.");
        }
      } catch (error) {
        logErrorFunction("Error fetching entity details:"+ error);
      }
    };

    fetchEntityDetails();
  }, [EntityId, accessToken]);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoadingUsers(true);
      try {
        const response = await dotnetApiClient().get(
          `/UiUser/billingentity/${EntityId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setUsers(response.data);
      } catch (error) {
        
        logErrorFunction("Error fetching users:"+ error);
      } finally {
        setLoadingUsers(false);
      }
    };

    fetchUsers();
  }, [EntityId, accessToken]);

  const handleDeleteUser = async (userId: string) => {
    try {
      await dotnetApiClient().put(`/UiUser/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const response = await dotnetApiClient().get(
        `/UiUser/billingentity/${EntityId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setUsers(response.data);
    } catch (error) {
      logErrorFunction("Error deleting user:"+ error);
    }
  };

  useEffect(() => {
    if (EntityId) {
      setBtnLoading(true);
      dispatch(getBillingDetails({ id: EntityId })).finally(() => {
        setBtnLoading(false);
      });
    }
  }, [EntityId, dispatch]);

  const onResetKey = (keyType: string) => {
    const _request = { id: entityDetails?.id };
    setBtnLoading(true);
    if (keyType === "primaryKey") {
      dispatch(resetPrimaryKey(_request)).finally(() => {
        setBtnLoading(false);
      });
    } else {
      dispatch(resetSecondaryKey(_request)).finally(() => {
        setBtnLoading(false);
      });
    }
  };

  const toggleVisibility = (keyType: string) => {
    if (keyType === "primaryKey") {
      setShowPrimaryKey(!showPrimaryKey);
    } else {
      setShowSecondaryKey(!showSecondaryKey);
    }
  };

  const fields = [
    { label: "Id", value: entityDetails?.id },
    { label: "Name", value: entityDetails?.billingName },
    { label: "Type", value: entityDetails?.billingType },
    { label: "Disabled on", value: entityDetails?.disabledOn },
    { label: "Billing Id", value: entityDetails?.billingId },
  ];

  const enableDisableLabel = entityDetails?.disabledOn ? "Enable" : "Disable";

  const handleEnableDisable = () => {
    const _request = { id: entityDetails?.id };
    if (entityDetails?.disabledOn) {
      dispatch(enableKey(_request)).finally(() => {
        setBtnLoading(false);
      });
    } else {
      dispatch(disabledKey(_request)).finally(() => {
        setBtnLoading(false);
      });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    setNewUser((prevUser) => ({ ...prevUser, userPersona: e.target.value }));
  };

  // Handle new user form submission
  const handleCreateUser = async () => {
    setCreatingUser(true);
    try {
      await dotnetApiClient().post(
        "/UiUser/lawfirm/user",
        {
          ...newUser,
          billingEntityId: EntityId,
          createdBy: EntityId,
          userPersona: newUser.userPersona,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const response = await dotnetApiClient().get(
        `/UiUser/billingentity/${EntityId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setUsers(response.data);
      setNewUser({
        name: "",
        email: "",
        phoneNumber: "",
        userPersona: "Lawyer",
      });
      setShowNewUserForm(false);
    } catch (error) {
      logErrorFunction("Error creating user:"+ error);
    } finally {
      setCreatingUser(false);
    }
  };

  // Extract billingType from entityDetails
  const billingType = entityDetails?.billingType;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Billing Entity Details</DialogTitle>
      <DialogContent>
        {btnLoading ? (
          <CircularProgress />
        ) : (
          <>
            <div className="userViewDetail">
              {fields.map((field) => (
                <div key={field.label} style={{ marginBottom: "10px" }}>
                  <strong>{field.label}:</strong> {field.value || "-"}
                </div>
              ))}
              {["Primary", "Secondary"].map((key) => (
                <div className="basicDetails" key={key}>
                  <div style={{ marginBottom: "10px" }}>
                    <strong>{key} Key:</strong>{" "}
                    {key === "Primary"
                      ? showPrimaryKey
                        ? entityDetails?.primaryKey
                        : "************"
                      : showSecondaryKey
                      ? entityDetails?.secondaryKey
                      : "************"}
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mr: 2 }}
                      onClick={() =>
                        toggleVisibility(`${key.toLowerCase()}Key`)
                      }
                    >
                      {key === "Primary"
                        ? showPrimaryKey
                          ? "Hide"
                          : "Show"
                        : showSecondaryKey
                        ? "Hide"
                        : "Show"}{" "}
                    </Button>
                    <Button
                      color="error"
                      variant="contained"
                      onClick={() => onResetKey(`${key.toLowerCase()}Key`)}
                    >
                      Reset
                    </Button>
                  </div>
                </div>
              ))}
            </div>

            <div>
              <Button
                variant="contained"
                color={entityDetails?.disabledOn ? "success" : "error"}
                onClick={handleEnableDisable}
                disabled={btnLoading}
              >
                {enableDisableLabel}
              </Button>
            </div>
            {billingType === "LawyerApp" && (
              <div>
                <Button
                  variant="outlined"
                  onClick={() => setShowNewUserForm(!showNewUserForm)}
                  className="adduser-btn"
                >
                  {showNewUserForm ? "Cancel" : "Add New User"}
                </Button>
              </div>
            )}

            {billingType === "LawyerApp" && (
              <>
                {showNewUserForm && (
                  <div className="new-form">
                    <TextField
                      label="Name"
                      name="name"
                      value={newUser.name}
                      onChange={handleInputChange}
                    />
                    <TextField
                      label="Email"
                      name="email"
                      value={newUser.email}
                      onChange={handleInputChange}
                    />
                    <TextField
                      label="Phone Number"
                      name="phoneNumber"
                      value={newUser.phoneNumber}
                      onChange={handleInputChange}
                    />
                    <FormControl>
                      <InputLabel>User Persona</InputLabel>
                      <Select
                        value={newUser.userPersona}
                        onChange={handleSelectChange}
                      >
                        <MenuItem value="Lawyer">Lawyer</MenuItem>
                        <MenuItem value="Client">Client</MenuItem>
                      </Select>
                    </FormControl>
                    <Button
                      variant="contained"
                      onClick={handleCreateUser}
                      disabled={creatingUser}
                      className="adduser-btn"
                    >
                      {creatingUser ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Add User"
                      )}
                    </Button>
                  </div>
                )}

                <div className="adduser-btn">
                  <FormControl>
                    <InputLabel>Filter by User Persona</InputLabel>
                    <Select
                      value={userPersona}
                      onChange={(e) => setUserPersona(e.target.value as string)}
                    >
                      <MenuItem value="Lawyer">Lawyer</MenuItem>
                      <MenuItem value="Client">Client</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Phone Number</TableCell>
                        <TableCell>User Persona</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loadingUsers ? (
                        <TableRow>
                          <TableCell colSpan={4} align="center">
                            <CircularProgress />
                          </TableCell>
                        </TableRow>
                      ) : (
                        users
                          .filter((user) => user.userPersona === userPersona)
                          .map((user) => (
                            <TableRow key={user.id}>
                              <TableCell>{user.name}</TableCell>
                              <TableCell>{user.email}</TableCell>
                              <TableCell>{user.phoneNumber}</TableCell>
                              <TableCell>{user.userPersona}</TableCell>
                              <TableCell>
                                <Button
                                  color="error"
                                  onClick={() => handleDeleteUser(user.id)}
                                >
                                  Delete
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
