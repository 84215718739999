import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setLoading,
  notificationFail,
  notificationSuccess,
} from "../slices/notificationSlice";
import dotnetApiClient from "../../config/dotnetApiClient";
import { setAllBillingData, setEntityData } from "../slices/billingSlice";

export const getAllBillingEntities: any = createAsyncThunk(
  "getAllBillingEntities",
  async (_request, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().get(`/Billing`);
      dispatch(setLoading(false));

      if (response?.data) {
        dispatch(setAllBillingData(response?.data));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);
export const addB2BEntity: any = createAsyncThunk(
  "addB2BEntity",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().post(`Billing`, _request);
      dispatch(setLoading(false));

      if (response?.data) {
        dispatch(getAllBillingEntities());
        dispatch(notificationSuccess("Added successfully"));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const resetPrimaryKey: any = createAsyncThunk(
  "resetPrimaryKey",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().post(
        `Billing/${_request.id}/reset-primary-key`,
        _request
      );
      dispatch(setLoading(false));
      if (response?.data) {
        dispatch(getBillingDetails(_request));
        dispatch(notificationSuccess("Primary-key reset successfully"));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);
export const resetSecondaryKey: any = createAsyncThunk(
  "resetSecondaryKey",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().post(
        `Billing/${_request.id}/reset-secondary-key`,
        _request
      );
      dispatch(setLoading(false));
      if (response?.data) {
        dispatch(getBillingDetails(_request));
        dispatch(notificationSuccess("Secondary-key reset successfully"));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const disabledKey: any = createAsyncThunk(
  "disabledKey",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().post(
        `Billing/${_request.id}/disable`,
        _request
      );
      dispatch(setLoading(false));
      if (response?.data) {
        dispatch(getAllBillingEntities());
        dispatch(getBillingDetails(_request));
        dispatch(notificationSuccess("Reset successfully"));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);
export const enableKey: any = createAsyncThunk(
  "disabledKey",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().post(
        `Billing/${_request.id}/enable`,
        _request
      );
      dispatch(setLoading(false));
      if (response?.data) {
        dispatch(getAllBillingEntities());
        dispatch(getBillingDetails(_request));
        dispatch(notificationSuccess("Reset successfully"));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);
export const getBillingDetails: any = createAsyncThunk(
  "getBillingDetails",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().get(
        `Billing/${_request.id}`,
        _request
      );
      dispatch(setLoading(false));
      if (response?.data) {
        dispatch(setEntityData(response?.data));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);
