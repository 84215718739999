import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allBillingData: [],
  entityDetails:null
};

export const billingReducer = createSlice({
  name: "billing",
  initialState,
  reducers: {
    setAllBillingData: (state, action) => ({
      ...state,
      allBillingData: action.payload,
    }),
    setEntityData: (state, action) => ({
      ...state,
      entityDetails: action.payload,
    }),
  },
});

export const { setAllBillingData ,setEntityData} = billingReducer.actions;
export default billingReducer.reducer;
